import { CycleArray } from "./utils";
import { mapValues } from "./utils";
import { Controller } from "@hotwired/stimulus";

import FontFaceObserver from "fontfaceobserver";

export default class extends Controller {
  static targets = ["items", "item"];
  connect() {
    this.animate = this.animate.bind(this);

    // Vars
    this.timeOut = null;
    this.pos = 0;
    this.totalWidth = 0;
    this.speed = 0;
    this.isAnimating = true;
    this.layout = this.layout.bind(this); // Needed to fix 'this' inside the setTimeout

    const font = new FontFaceObserver("calibri");

    font.load().then(() => {
      this.layout();
    });

    this.layout();
    this.animationId = requestAnimationFrame(this.animate);
  }

  disconnect() {
    cancelAnimationFrame(this.animationId);
  }

  handleResize() {
    if (this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(this.layout, 100);
  }

  layout() {
    this.speed = mapValues(window.innerWidth, 400, 2000, 0.5, 0.75);

    this.element
      .querySelectorAll(".Ticker-item.extra")
      .forEach((el) => el.remove());

    const items = CycleArray.from(this.itemTargets);
    const itemWidths = items.map((el) => {
      return el.getBoundingClientRect().width;
    });
    this.totalWidth = itemWidths.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    let width = 0;
    while (width < window.innerWidth) {
      for (var i = 0; i < itemWidths.length; i++) {
        const itemWidth = itemWidths[i];
        const el = items[i].cloneNode(true);
        el.classList.add("extra");
        this.itemsTarget.appendChild(el);
        width += itemWidth;

        if (width >= window.innerWidth) {
          break;
        }
      }
    }
  }

  animate() {
    if (this.totalWidth > 0) {
      if (this.pos <= this.totalWidth) {
        this.pos += this.speed;
        this.itemsTarget.style = `transform: translateX(-${this.pos}px)`;
      } else {
        this.pos = 0;
      }
    }
    this.animationId = requestAnimationFrame(this.animate);
  }

  handleControlButtonClick(e) {
    e.preventDefault();
    this.isAnimating = !this.isAnimating;
    if (this.isAnimating) {
      this.animate();
    } else {
      cancelAnimationFrame(this.animationId);
    }
  }
}
