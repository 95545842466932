import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";
import { getUrlAndTitleFromLink } from "./utils";

export default class extends Controller {
  static targets = ["memoryListPageButton", "liveSocialPageButton"];

  static values = {
    activePage: String,
  };

  connect() {
    this.submitting = false;
    if (this.activePageValue === "memoryListPage") {
      this.memoryListPageButtonTarget.classList.add("active");
    } else if (this.activePageValue === "liveSocialPage") {
      this.liveSocialPageButtonTarget.classList.add("active");
    }
  }

  handleMemoriesButtonClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    const { url, pageTitle } = getUrlAndTitleFromLink(event.currentTarget);

    window
      .fetch(url, {
        headers: HEADERS,
      })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
        window.dispatchEvent(
          new CustomEvent("NavigateTo", { detail: { url, pageTitle } })
        );
      })
      .catch((error) => {
        this.element.innerText = error;
        console.error("Error:", error);
      });
  }

  handleLiveSocialButtonClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    const { url, pageTitle } = getUrlAndTitleFromLink(event.currentTarget);

    window
      .fetch(url, {
        headers: HEADERS,
      })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
        window.dispatchEvent(
          new CustomEvent("NavigateTo", { detail: { url, pageTitle } })
        );
      })
      .catch((error) => {
        this.element.innerText = error;
        console.error("Error:", error);
      });
  }
}
