import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  connect() {
    this.setupSelects();
  }

  setupSelects() {
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      const select = this.element.querySelector(`[name=${key}]`);
      if (select) {
        select.value = value;
        const options = select.querySelectorAll("option");
        options.forEach((option) => {
          if (option.value === value) {
            option.selected = true;
            const label = select.parentElement.querySelector(
              "[data-selectWidget-target='label']"
            );
            if (label) {
              label.innerText = option.innerText;
            }
          }
        });
      }
    });
  }

  handleSelectChange(event) {
    this.updateFilters(event.currentTarget.value, event.currentTarget.name);
  }

  updateFilters(filterValue, filterType) {
    const params = new URLSearchParams(window.location.search);

    if (filterValue === "") {
      params.delete(filterType);
    } else {
      params.set(filterType, filterValue);
    }

    let paramString = params.toString();
    if (paramString !== "") {
      paramString = `?${paramString}`;
    }

    const url = window.location.origin + window.location.pathname + paramString;

    window.history.pushState({ path: url }, "", url);

    window
      .fetch(url, {
        headers: HEADERS,
      })
      .then((response) => response.text())
      .then((text) => {
        window.dispatchEvent(
          new CustomEvent("FilterChanged", { detail: text })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
