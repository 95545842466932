import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["embed"];

  static values = {
    id: String,
  };

  connect() {
    this.embed = window.flcklr.Embeds.create(
      window.flcklr.EmbedConfigs[this.idValue]
    );
  }

  disconnect() {
    this.embed.destroy();
    this.embed = null;
  }
}
